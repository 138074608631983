<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import axios from 'axios';

export default {
    data: function () {
		return {
			character: []
		}
	},
	created: async function () {
		await this.fetchCharacterData()
	},
	methods: {
		fetchCharacterData: function () {
            const uri = process.env.VUE_APP_XIVAPI + '/character/' + this.$route.params.charid
            this.debugLevel('URI loaded: ' + uri)

			return axios.get(uri).then((response) => {
				this.character = response.data
                document.title = response.data.name + ' | XIVDATA';
				this.debugLevel(['Character data successfully loaded: ', this.character])
			}).catch((error) => {
				this.debugLevel('Character data failed to load with error: ' + error)
			})
		},

        debugLevel: function (value, level = 2) {
            const values = [];
            Array.isArray(value) ? value.forEach(element => values.push(element)) : values[0] = value;
            switch (level) {
                case 1:
                if (process.env.NODE_ENV == 'test' || process.env.NODE_ENV == 'development') {
                    values.forEach(element => console.log(element));
                }
                break;
                case 2:
                if (process.env.NODE_ENV == 'development') {
                    values.forEach(element => console.log(element));
                  }
                break;
                default:
                    values.forEach(element => console.log(element));
                break;
            }
        },
	},
  page: {
    title: "ClassJobs",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout
  },
};
</script>
<template>
    <Layout>
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="page-title-box">
                    <h4 class="page-title">{{ character.name }}</h4>
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">XIVDATA</li>
                        <li class="breadcrumb-item">{{ $t('messages.character') }}</li>
                        <li class="breadcrumb-item active">{{ character.name }}</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-xl-2">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title font-16 mt-0"><img class="img-fluid rounded rounded-circle" :src="character.avatar"> {{ character.name }} <i v-if="character.gender == 2" class="far fa-venus"></i><i v-if="character.gender == 1" class="far fa-mars"></i></h4>
                                    </div>
                                    <div class="nav flex-column nav-pills">
                                        <router-link :to="{path: '/character/' + character.charid}" class="nav-link"><i class="fas fa-home"></i> {{ $t('character.info_button') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/portrait'}" class="nav-link"><i class="far fa-user"></i> {{ $t('character.portrait') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/classjobs'}" class="nav-link"><i class="far fa-envelope"></i> {{ $t('character.classjobs') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/mounts'}" class="nav-link"><i class="fas fa-horse"></i> {{ $t('character.mounts') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/minions'}" class="nav-link"><i class="fas fa-cat"></i> {{ $t('character.minions') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/archievments'}" class="nav-link"><i class="fas fa-trophy"></i> {{ $t('character.archievments') }}</router-link>
                                        <router-link :to="{path: '/character/' + character.charid + '/screenshots'}" class="nav-link"><i class="fas fa-images"></i> {{ $t('character.screenshots') }}</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-xl-10">
                                <b-tabs justified pills class="navtab-bg" content-class="p-3">
                                    <b-tab active>
                                        <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">{{ $t('cj.dow') }}</span>
                                        </template>
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-xl-6">
                                                <h4>{{ $t('cj.tanks') }}</h4>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">{{ $t('cj.paladin') }}</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 80</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 0 / {{ $t('character.percent') }} 100</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-success" style="width: 100%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="card-body">
                                                        <h5 class="card-title">{{ $t('cj.warrior') }}</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 31</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 4</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 4%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">{{ $t('cj.darkknight') }}</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 74</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 8.954.634 / {{ $t('character.percent') }} 55</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-info" style="width: 55%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">{{ $t('cj.gunbreaker') }}</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 70</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 59</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 59%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-xl-6">
                                                <h4>{{ $t('cj.meele')}}</h4>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Mönch</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 36</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 88</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 88%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">Dragoon</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 30</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 60</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 60%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">Ninja</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 12</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 87</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 87%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">Samurai</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 14</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 64</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 64%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-xl-6">
                                                <h4>{{ $t('cj.healer') }}</h4>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Weißmagier</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 37</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 39</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 39%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">Gelehrter</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 50</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 93</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 93%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">Astrologe</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 37</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 44</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 44%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-xl-6">
                                                <h4>{{ $t('cj.ranged') }}</h4>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Barde</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 74</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 13</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 13%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">Maschinist</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 48</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 12</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 12%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">Tänzer</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 13</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 26</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 26%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-xl-6">
                                                <!-- Placeholder -->
                                            </div>
                                            <div class="col-md-3 col-lg-3 col-xl-5">
                                                <h4>{{ $t('cj.mage') }}</h4>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Schwarzmagier</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 14</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 29</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 29%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">Beschwörer</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 15</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 74</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 74%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">Rotmagier</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 37</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 80</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 80%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title">Blaumagier</h5>
                                                        <h6 class="card-subtitle mb-2 text-muted">{{ $t('character.level') }}: 40</h6>
                                                        <p class="card-text">{{ $t('character.exptilnextlvl') }} 169393 / {{ $t('character.percent') }} 52</p>
                                                        <div class="progress mb-3">
                                                            <div class="progress-bar bg-warning" style="width: 52%" role="progressbar" aria-valuenow="$cj->ExpLevel" aria-valuemin="0" aria-valuemax="$cj->ExpLevelMax"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab>
                                        <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="far fa-user"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">{{ $t('cj.dolh') }}</span>
                                        </template>
                                        <p class="mb-0">WIP</p>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>